import React, { useState, useEffect } from "react"
import {
  Button,
  Grid,
  Typography,
  Dialog,
  Paper,
  DialogActions,
  DialogContent,
} from "@material-ui/core"

import { Link, useI18next, Trans } from "gatsby-plugin-react-i18next"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Wave from "../components/SeperatorWave"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Lottie from "react-lottie"
import * as orderAnimationData from "../images/lottie/order2.json"
import * as cookAnimationData from "../images/lottie/cook3.json"
import * as deliveryAnimationData from "../images/lottie/delivery3.json"

import Plx from "react-plx"

import { window } from "browser-monads" //npm i browser-monads

const brands = {
  bite: {
    foodoraLink: "https://www.foodora.se/restaurant/new/slne/bite",
    //biteLink: "https://bitekitchens.com/order/home",
    name: "Bite",
  },
  smashingBurgers: {
    foodoraLink: "https://www.foodora.se/restaurant/new/d9jg/smashing-burgers",
    // biteLink: "https://bitekitchens.com/order/smashing-burgers",
    name: "Smashing Burgers",
  },
  pokiDoki: {
    foodoraLink: "http://foodora.se/restaurant/new/qm2l/pokidoki",
    //biteLink: "https://bitekitchens.com/order/poki-doki",
    name: "Poki Doki",
  },
  chickNBuns: {
    foodoraLink: "http://foodora.se/restaurant/new/oece/chick-n-buns",
    //biteLink: "https://bitekitchens.com/order/chick-n-buns",
    name: "Chick n' Buns",
  },
  happyRoots: {
    foodoraLink: "http://foodora.se/restaurant/new/hbez/happy-roots",
    // biteLink: "https://bitekitchens.com/order/happy-roots",
    name: "Happy Roots",
  },
  wonderWings: {
    foodoraLink: "https://www.foodora.se/restaurant/new/c13q/wonder-wings",
    //biteLink: "https://bitekitchens.com/order/wonder-wings",
    name: "Wonder Wings",
  },
  tastySquare: {
    foodoraLink: "http://foodora.se/restaurant/new/y0az/tasty-squared",
    //biteLink: "https://bitekitchens.com/order/tasty-square",
    name: "Tasty Square",
  },
  tacos: {
    foodoraLink: "http://foodora.se/restaurant/new/y0az/tasty-squared",
    //biteLink: "https://bitekitchens.com/order/tasty-square",
    name: "La Casa de Tacos",
  },
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    adventure: file(relativePath: { eq: "adventure.png" }) {
      ...adventure
    }
    adventure2: file(relativePath: { eq: "adventure2.png" }) {
      ...adventure2
    }
    screenshot: file(relativePath: { eq: "screenshot.png" }) {
      ...app
    }
    download_app_qr: file(relativePath: { eq: "download-app-qr.png" }) {
      ...image
    }
    download_appstore: file(relativePath: { eq: "download_appstore.png" }) {
      ...image
    }
    download_playstore: file(relativePath: { eq: "download_playstore.png" }) {
      ...image
    }
    blazinLogo: file(relativePath: { eq: "blazinhotchicken.png" }) {
      ...image
    }
    wonderLogo: file(relativePath: { eq: "wonder-wings.png" }) {
      ...image
    }
    templeLogo: file(relativePath: { eq: "templeofwings.png" }) {
      ...image
    }
    chicknbunsPoster: file(
      relativePath: { eq: "posters/chicknbuns-poster.jpg" }
    ) {
      ...image
    }
    happyrootsPoster: file(
      relativePath: { eq: "posters/happyroots-poster.jpg" }
    ) {
      ...image
    }
    pokidokiPoster: file(relativePath: { eq: "posters/pokidoki-poster.jpg" }) {
      ...image
    }
    smashingburgersPoster: file(
      relativePath: { eq: "posters/smashingburgers-poster.jpg" }
    ) {
      ...image
    }
    tastysquarePoster: file(
      relativePath: { eq: "posters/tastysquare-poster.jpg" }
    ) {
      ...image
    }
    wonderwingsPoster: file(
      relativePath: { eq: "posters/wonderwings-poster.jpg" }
    ) {
      ...image
    }
    tacosPoster: file(relativePath: { eq: "posters/tacos.jpg" }) {
      ...image
    }
    foodoraLogo: file(relativePath: { eq: "foodora.png" }) {
      ...image
    }
    biteTakeawayLogo: file(relativePath: { eq: "bite-takeaway.png" }) {
      ...image
    }
  }
`

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

const getSticky = windowSize => [
  {
    start: "self",
    startOffset: "20vh",
    duration: "40vh",
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: "self",
    startOffset:
      typeof windowSize !== "undefined" && windowSize.width > 720
        ? "90vh"
        : "130vh",
    duration: "40vh",
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
]

const App = ({ data }) => {
  const { t } = useI18next()
  const images = data
  //const images = useStaticQuery(imageQuery);

  const [openRestaurant, setOpenRestaurant] = React.useState(null)

  const [adventureOpen, setAdventureOpen] = React.useState(true)

  const windowSize = useWindowSize()

  return (
    <>
      {false && (
        <Dialog
          open={adventureOpen}
          PaperProps={{ style: { margin: 12, flexGrow: 1, maxWidth: 550 } }}
          onClose={() => setAdventureOpen(false)}
        >
          <GatsbyImage
            image={images.adventure.childImageSharp.gatsbyImageData}
            alt="app"
            loading="eager"
            className={"adventure hidemobile"}
          />

          <GatsbyImage
            image={images.adventure2.childImageSharp.gatsbyImageData}
            alt="app"
            loading="eager"
            className={"adventure hidedesktop"}
          />

          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAdventureOpen(false)}
            >
              Stäng rutan
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Layout showBrands={false} colorClass="normalColor">
        <SEO title={t("SEO_title_bite")} description={t("SEO_desc_bite")} />
        <Hero
          colorClass="mainColor"
          image={
            <Plx
              className="MyAwesomeParallax"
              disabled={windowSize.width < 720}
              parallaxData={[
                {
                  start: 0,
                  end: 800,
                  properties: [
                    {
                      startValue: 0,
                      endValue: -125,
                      property: "translateY",
                    },
                  ],
                },
              ]}
            >
              <GatsbyImage
                image={images.screenshot.childImageSharp.gatsbyImageData}
                className={"img appScreenshot"}
                alt="app screenshot"
                loading="eager"
              />
            </Plx>
          }
        >
          <Typography
            variant="h1"
            className={"siteTitle"}
            style={{ marginTop: 20 }}
          >
            {t("App_desc")}
          </Typography>
          <Typography
            variant="h1"
            className={"siteTitle2"}
            style={{
              marginTop: 20,
              fontSize: 16,
              fontFamily: "Montserrat",
              fontWeight: 400,
              whiteSpace: "break-spaces",
            }}
          >
            {t("SecondSlogan")}
          </Typography>
          {false && (
            <Typography
              variant="h1"
              className={"siteTitle"}
              style={{
                marginTop: 20,
                fontSize: 20,
                fontFamily: "Montserrat",
                fontWeight: 400,
              }}
            >
              {t("Now in Lund")}
            </Typography>
          )}
          <Grid container spacing={2} className={"ctas"}>
            <Grid
              item
              xs={12}
              sm={12}
              className={"hidemobile"}
              style={{ marginTop: -20, marginBottom: 12 }}
            >
              <a href={"http://app.bitekitchens.com"}>
                <GatsbyImage
                  image={images.download_app_qr.childImageSharp.gatsbyImageData}
                  style={{ width: 220 }}
                  className={"img"}
                  alt="app screenshot"
                  loading="eager"
                />
              </a>
            </Grid>
            <Grid item className={"download_button"}>
              <a href={"http://app.bitekitchens.com"}>
                <GatsbyImage
                  image={
                    images.download_appstore.childImageSharp.gatsbyImageData
                  }
                  className={"img "}
                  alt="app screenshot"
                  loading="eager"
                />
              </a>
            </Grid>
            <Grid item className={"download_button"}>
              <a href={"http://app.bitekitchens.com"}>
                <GatsbyImage
                  image={
                    images.download_playstore.childImageSharp.gatsbyImageData
                  }
                  className={"img"}
                  alt="app screenshot"
                  loading="eager"
                />
              </a>
            </Grid>
          </Grid>
        </Hero>
        <Plx
          className="info"
          parallaxData={getSticky(windowSize)}
          style={{ opacity: 0 }}
        >
          <div
            className={"info firstInfo desc reverse"}
            style={{ margin: "80px 0" }}
            id="about"
          >
            <Typography
              variant="h1"
              align="center"
              style={{ fontWeight: "bold" }}
              gutterBottom
            >
              {t("bite_description_title")}
            </Typography>
            <Typography paragraph align="center">
              {t("bite_description")}
            </Typography>
          </div>
        </Plx>

        <Plx
          style={{ margin: "0 -24px", paddingBottom: 100 }}
          className={"firstInfo"}
          parallaxData={[
            {
              start: ".info2",
              duration: "15vh",
              startOffset: "50vh",
              properties: [
                {
                  startValue: "#212322",
                  endValue: "#2C3191",
                  property: "backgroundColor",
                },
              ],
            },
            {
              start: ".info3",
              duration: "15vh",
              startOffset: "50vh",
              properties: [
                {
                  startValue: "#2C3191",
                  endValue: "#2db676",
                  property: "backgroundColor",
                },
              ],
            },
          ]}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "100px 0",
            }}
            className="info1"
          >
            <Plx
              className="info"
              parallaxData={getSticky(windowSize)}
              style={{ opacity: 0 }}
            >
              <Lottie
                options={{
                  animationData: orderAnimationData,
                  //initialSegment: [0, 75],
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                //segments={[0, 75]}
                isClickToPauseDisabled={true}
                height={200}
                width={200}
              />
              <Typography
                variant="h1"
                style={{
                  textAlign: "center",
                  marginTop: 24,
                  color: "white",
                }}
                gutterBottom
              >
                {t("step1_title")}
              </Typography>
              <Typography className={"infodesc"}>{t("step1_desc")}</Typography>
              <Grid container className={"boxes"} spacing={5} justify="center">
                <Grid item>
                  <Paper className={"paper-box"}>
                    <Typography variant="h5" align="center">
                      {t("step1_box1")}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={"paper-box "}>
                    <Typography variant="h5" align="center">
                      {t("step1_box2")}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Plx>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "100px 0",
            }}
            className="info2"
          >
            <Plx
              className="info"
              parallaxData={getSticky(windowSize)}
              style={{ opacity: 0 }}
            >
              <Lottie
                options={{
                  animationData: cookAnimationData,
                  //initialSegment: [0, 75],
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                //segments={[0, 75]}
                isClickToPauseDisabled={true}
                height={200}
                width={200}
              />
              <Typography
                variant="h1"
                gutterBottom
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: 24,
                }}
              >
                {t("step2_title")}
              </Typography>
              <Typography className={"infodesc"}>{t("step2_desc")}</Typography>
              <Grid container className={"boxes"} spacing={5} justify="center">
                <Grid item>
                  <Paper className={"paper-box"}>
                    <Typography variant="h5" align="center">
                      {t("step2_box1")}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={"paper-box"}>
                    <Typography variant="h5" align="center">
                      {t("step2_box2")}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Plx>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "100px 0",
            }}
            className="info3"
          >
            <Plx
              className="info"
              parallaxData={getSticky(windowSize)}
              style={{ opacity: 0 }}
            >
              <Lottie
                options={{
                  animationData: deliveryAnimationData,

                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                isClickToPauseDisabled={true}
                height={200}
                width={200}
              />
              <Typography
                variant="h1"
                gutterBottom
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: 24,
                }}
              >
                {t("step3_title")}
              </Typography>
              <Typography className={"infodesc"}>{t("step3_desc")}</Typography>
              <Grid container className={"boxes"} spacing={5} justify="center">
                <Grid item>
                  <Paper className={"paper-box"}>
                    <Typography variant="h5" align="center">
                      {t("step3_box1")}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={"paper-box"}>
                    <Typography variant="h5" align="center">
                      {t("step3_box2")}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={"paper-box"}>
                    <Typography variant="h5" align="center">
                      {t("step3_box3")}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Plx>
          </div>
        </Plx>
        <div style={{ backgroundColor: "#fcfcfc", margin: "0 -24px" }}>
          <div
            className={"info addPhoneMargin"}
            style={{ margin: "100px auto" }}
          >
            <Typography
              variant="h2"
              align="center"
              style={{ fontWeight: "bold", marginBottom: 64 }}
            >
              {t("Order now from our 7 brands")}
            </Typography>

            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={"logos"}
              spacing={6}
              justifyContent="center"
            >
              <Grid item sm={4} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <a href={brands["smashingBurgers"]?.biteLink}>
                    <GatsbyImage
                      image={
                        images.smashingburgersPoster.childImageSharp
                          .gatsbyImageData
                      }
                      className={"img burgerCursor"}
                      alt="Smashing Burgers logo"
                      loading="eager"
                    />
                    {false && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={"button"}
                      >
                        {t("Order now")}
                      </Button>
                    )}
                  </a>
                </div>
              </Grid>
              <Grid item sm={4} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <a href={brands["tacos"]?.biteLink}>
                    <GatsbyImage
                      image={images.tacosPoster.childImageSharp.gatsbyImageData}
                      className={"img tacosCursor"}
                      alt="La Casa de Tacos logo"
                      loading="eager"
                    />
                    {false && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={"button"}
                      >
                        {t("Order now")}
                      </Button>
                    )}
                  </a>
                </div>
              </Grid>
              <Grid item sm={4} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <a href={brands["happyRoots"]?.biteLink}>
                    <GatsbyImage
                      image={
                        images.happyrootsPoster.childImageSharp.gatsbyImageData
                      }
                      className={"img saladCursor"}
                      alt="Happy Roots logo"
                      loading="eager"
                    />
                    {false && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={"button"}
                      >
                        {t("Order now")}
                      </Button>
                    )}
                  </a>
                </div>
              </Grid>
              <Grid item sm={4} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <a href={brands["pokiDoki"]?.biteLink}>
                    <GatsbyImage
                      image={
                        images.pokidokiPoster.childImageSharp.gatsbyImageData
                      }
                      className={"img pokeCursor"}
                      alt="Poki Doki logo"
                      loading="eager"
                    />
                    {false && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={"button"}
                      >
                        {t("Order now")}
                      </Button>
                    )}
                  </a>
                </div>
              </Grid>
              <Grid item sm={4} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <a href={brands["chickNBuns"]?.biteLink}>
                    <GatsbyImage
                      image={
                        images.chicknbunsPoster.childImageSharp.gatsbyImageData
                      }
                      className={"img burgerCursor"}
                      alt="Chick n' Buns logo"
                      loading="eager"
                    />
                    {false && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={"button"}
                      >
                        {t("Order now")}
                      </Button>
                    )}
                  </a>
                </div>
              </Grid>
              <Grid item sm={4} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <a href={brands["wonderWings"]?.biteLink}>
                    <GatsbyImage
                      image={
                        images.wonderwingsPoster.childImageSharp.gatsbyImageData
                      }
                      className={"img wonderCursor"}
                      alt="Wonder Wings logo"
                      loading="eager"
                    />
                    {false && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={"button"}
                      >
                        {t("Order now")}
                      </Button>
                    )}
                  </a>
                </div>
              </Grid>
              <Grid item sm={4} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <a href={brands["tastySquare"]?.biteLink}>
                    <GatsbyImage
                      image={
                        images.tastysquarePoster.childImageSharp.gatsbyImageData
                      }
                      className={"img pizzaCursor"}
                      alt="Tasty Square logo"
                      loading="eager"
                    />
                    {false && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={"button"}
                      >
                        {t("Order now")}
                      </Button>
                    )}
                  </a>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="info desc addPhoneMargin">
            <div>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold" }}
                align="center"
              >
                {t("bite_foodCourt_title")}
              </Typography>
              <Typography align="center">{t("bite_foodCourt")}</Typography>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default App
